export const GET_USER = `query getUser {
    getUser {
        id
        firstName
        lastName
        email
        phone
        document
        agronomist
        country
        avatar
    }
}`;

export const UPDATE_USER = `mutation updateUser($input: UpdateUserInput!){
    updateUser(input: $input){
        firstName
        lastName
        email
        phone
        document
        agronomist
        country
        avatar
    }
}`;

export const GET_USER_ADDRESSES = `
query getUserAddresses {
    getUserAddresses {
        addresses {
            id
            userId
            name
            street
            streetNumber
            internalNumber
            neighborhood
            city
            state
            country
            zipCode
            geolocation {
                lat
                lng
            }
            isCurrent
        }
        billingAddresses {
            id
            address {
                id
                userId
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
                isCurrent
            }
            legalName
            taxId
        }
        shippingAddresses {
            id
            address {
                id
                userId
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
                isCurrent
            }
            email
            phone
        }
    }
} `;

export const CREATE_ADDRESS = `
mutation addUserAddress($input: CreateAddressInput!) {
    addUserAddress(input: $input) {
        id
        userId
        name
        street
        streetNumber
        internalNumber
        neighborhood
        city
        state
        country
        zipCode
        geolocation {
            lat
            lng
        }
        isCurrent
    }
}
`;

export const CREATE_BILLING_ADDRESS = `
mutation addUserBillingAddress($input: CreateBillingAddressInput!) {
    addUserBillingAddress(input: $input) {
        id
        address {
            id
            userId
            name
            street
            streetNumber
            internalNumber
            neighborhood
            city
            state
            country
            zipCode
            geolocation {
                lat
                lng
            }
            isCurrent
        }
        legalName
        taxId
    }
}
`;

export const CREATE_SHIPPING_ADDRESS = `
mutation addUserShippingAddress($input: CreateShippingAddressInput!) {
    addUserShippingAddress(input: $input) {
        id
        address {
            id
            userId
            name
            street
            streetNumber
            internalNumber
            neighborhood
            city
            state
            country
            zipCode
            geolocation {
                lat
                lng
            }
            isCurrent
        }
        email
        phone
    }
}
`;

export const GET_USER_CREDIT = `query getUserCredit {
    getUserCredit {
        id
        creditId
        userId
        userEmail
        financialProviderId
        status
        movements {
            id
            userCreditId
            orderId
            amount {
                value
                currency
            }
            type
            created
        }
        authorizedAmount {
            value
            currency
        }
        usedAmount {
            value
        }
        feesAmount {
            value
        }
        interestAmount {
            value
        }
        created
        updated
    }
}`;

export const ADD_USER_CREDIT = `mutation addUserCredit($input: AddUserCreditInput!) {
    addUserCredit(input: $input) {
        id
        creditId
        userId
        financialProviderId
        status
        movements
        authorizedAmount {
            value
            currency
        }
        usedAmount {
            value
            currency
        }
        feesAmount {
            value
            currency
        }
        interestAmount {
            value
            currency
        }
        created
        updated
    }
}`;

export const UPDATE_USER_CREDIT = `mutation updateUserCredit($input: UpdateUserCreditInput!) {
    updateUserCredit(input: $input) {
        id
        creditId
        userId
        userEmail
        financialProviderId
        status
        movements
        authorizedAmount {
            value
            currency
        }
        usedAmount {
            value
        }
        feesAmount {
            value
        }
        interestAmount {
            value
        }
        created
        updated
    }
}`;
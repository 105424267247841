import { useEffect, useState } from 'react';
import { signIn } from 'aws-amplify/auth';
import useWindowSize from '../hooks/useWindowSize';
import useAuthProvider from '../hooks/useAuthProvider';
import { filterNameTranslationStrings } from '../constants';
import { cognitoErrorMessages } from '../errors/login-errors';
import { HomeProduct } from 'types/productTypes';
import { UserErrors, UserLoginData, UserGeolocation } from 'types/userTypes';
import { FilterInput, ModifyFiltersProps } from 'types/filterTypes';
import { CategoryProvider, ProviderProduct, ProviderDetails } from 'types/providerTypes';

export const handleCognitoError = (error: UserErrors | string) => {
	if (typeof error !== 'string' && error?.message) {
		const message = Object.keys(cognitoErrorMessages).includes(error?.message)
			? cognitoErrorMessages[error.message].es
			: null;
		if (message) return message;
	}

	return cognitoErrorMessages.GENERIC_ERROR.es;
};

export const handleLoginSession = async (
	logUser: UserLoginData,
	setErrorMessage: Function,
	setErrorAlert: Function,
	getCurrentSession: Function,
) => {
	try {
		const { username, password } = logUser;
		const { isSignedIn, nextStep } = await signIn({ username, password });
		useAuthProvider(nextStep.signInStep, username, setErrorMessage, setErrorAlert);
		isSignedIn && getCurrentSession();
	} catch (error) {
		if ((error as Error)?.message !== undefined) {
			setErrorMessage(handleCognitoError(error as UserErrors));
		} else {
			setErrorMessage(handleCognitoError('GENERIC_ERROR'));
		}
		setErrorAlert(true);
	}
};

export const sortProducts = (products: ProviderProduct | HomeProduct[], type: string) => {
	switch (type) {
		case 'ascending':
			return products.sort((a, b) => a.amount.value - b.amount.value);

		case 'descending':
			return products.sort((a, b) => b.amount.value - a.amount.value);

		default:
			return products;
	}
};

export const getWindowSize = () => {
	const [isMobile, setIsMobile] = useState(true);
	const windowSize = useWindowSize();
	useEffect(() => {
		if (windowSize && windowSize < 1024) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [windowSize]);
	return isMobile;
};

export const getPrice = (price: number, currency: string) => {
	if (currency !== '' && currency !== null) {
		return Intl.NumberFormat(undefined, {
			currency,
			minimumFractionDigits: 2,
			style: 'currency',
		}).format(price ? price : 0);
	} else {
		return price ? price : 0;
	}
};

export const filterProducts = (providers: CategoryProvider[], filters: FilterInput) => {
	try {
		let filteredProviders = providers.filter(
			(provider: CategoryProvider) => provider?.length > 0,
		);
		if (filters.providerId.length > 0) {
			filteredProviders = filteredProviders.map((provider: CategoryProvider) =>
				provider.filter((product: ProviderProduct) =>
					filters.providerId.includes(product.provider?.name),
				),
			);
		}
		if (filters.state.length > 0) {
			filteredProviders = filteredProviders.map((provider: CategoryProvider) =>
				provider.filter((product: ProviderProduct) =>
					filters.state.includes(product.provider?.address?.state),
				),
			);
		}
		if (filters.city.length > 0) {
			filteredProviders = filteredProviders.map((provider: CategoryProvider) =>
				provider.filter((product: ProviderProduct) =>
					filters.city.includes(product.provider?.address?.city),
				),
			);
		}
		if (filters.brand.length > 0) {
			filteredProviders = filteredProviders.map((provider: CategoryProvider) =>
				provider.filter((product: ProviderProduct) =>
					filters.brand.includes(product.product?.brand),
				),
			);
		}
		if (filters.category.length > 0) {
			filteredProviders = filteredProviders.map((provider: CategoryProvider) =>
				provider.filter((product: ProviderProduct) =>
					filters.category.includes(product.product?.category),
				),
			);
		}
		if (filters.cropType.length > 0) {
			filteredProviders = filteredProviders.map((provider: CategoryProvider) =>
				provider.filter((product: ProviderProduct) =>
					product.product?.cropTypes.every((cropType) =>
						filters.cropType.includes(cropType),
					),
				),
			);
		}
		// ToDo: Add more filters here
		filteredProviders = filteredProviders.filter(
			(provider: CategoryProvider) => provider.length > 0,
		);
		return filteredProviders;
	} catch (error) {
		console.error('.::[Error filtering products]::.', error);
		throw error;
	}
};

export default { handleCognitoError, sortProducts, getWindowSize, getPrice, filterProducts };

export const modifyFilters = ({ item, filters, setFilters, filterName }: ModifyFiltersProps) => {
	const translatedName = filterNameTranslationStrings[filterName];
	if (translatedName) {
		let newFilters = { ...filters };
		if (
			Array.isArray(newFilters[translatedName]) &&
			newFilters[translatedName].includes(item)
		) {
			newFilters = {
				...filters,
				[translatedName]: newFilters[translatedName].filter(
					(filterItem) => filterItem !== item,
				),
			};
		} else {
			const oldSectionFilters = filters[translatedName];

			newFilters = {
				...filters,
				[translatedName]: [...oldSectionFilters, item],
			};
		}
		setFilters(newFilters);
		if (typeof window !== 'undefined') {
			localStorage.setItem('filters', JSON.stringify(newFilters));
		}
	}
};

const degreeToRadian = (degree: number) => (degree * Math.PI) / 180;

export const sortProvidersByDistance = (
	providers: ProviderDetails[],
	userGeo: { lat: number; lng: number },
) => {
	const providersWithDistance = providers.map((provider: Record<string, unknown>) => {
		const { geolocation } = provider.address as UserGeolocation;
		const userLat = userGeo.lat;
		const userLng = userGeo.lng;
		const providerLat = geolocation?.lat;
		const providerLng = geolocation?.lng;
		const userLatRad = degreeToRadian(userLat);
		const userLngRad = degreeToRadian(userLng);
		const providerLatRad = degreeToRadian(providerLat);
		const providerLngRad = degreeToRadian(providerLng);
		const deltaLat = userLatRad - providerLatRad;
		const deltaLng = userLngRad - providerLngRad;
		const R = 6371;
		const halfChordLengthSquared =
			Math.sin(deltaLat / 2) ** 2 +
			Math.cos(userLatRad) * Math.cos(providerLatRad) * Math.sin(deltaLng / 2) ** 2;
		const arcLength =
			2 *
			Math.atan2(Math.sqrt(halfChordLengthSquared), Math.sqrt(1 - halfChordLengthSquared));
		const distance = R * arcLength;
		return { ...provider, distance };
	});
	return providersWithDistance.sort((a, b) => a.distance - b.distance) as ProviderDetails[];
};

export function parseDate(date: number): string {
	const dateObj = new Date(date)
	return dateObj.toLocaleDateString('es-MX', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})
}

export function formatPrice(value: number, currency: string): string {
	return value.toLocaleString('es-MX', {
		style: 'currency',
		currency: currency,
	})
}